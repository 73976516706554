const TransferImage = (array, index) => {
  if (index > array.length - 1) {
    return [array[0], 0];
  } else {
    index++;
    return [array[index], index];
  }
};

export default TransferImage;
