import React, { useState } from "react";
import { observer } from "mobx-react";
import Button from "../../Common/Button/Button";
import instruction1 from "./../../Assets/Instructions/instruction 1.png";
import instruction2 from "./../../Assets/Instructions/instruction 2.png";
import instruction3 from "./../../Assets/Instructions/instruction 3.png";
import "./index.css";
import globalStore from "../../Store/GlobalStore";

const Instruction = () => {
  const imgs = [instruction1, instruction2, instruction3];
  const [pageIndex, setPageIndex] = useState(0);

  const nextImg = () => {
    if (pageIndex < 2) {
      setPageIndex(pageIndex + 1);
    } else {
      globalStore.changePage("home");
    }
  };
  const getImg = () => {
    return imgs[pageIndex];
  };

  return (
    <div className="instruction-container">
      <div id="instruction">
        <img src={getImg()}></img>
      </div>

      <div className="home-buttons instruction-btn">
        <Button
          name={pageIndex < 2 ? "dalje" : "početna"}
          action={() => nextImg()}
          classes="btn btn-light"
        />
      </div>
    </div>
  );
};
export default observer(Instruction);
