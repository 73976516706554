import globalStore from "../../../../Store/GlobalStore";
import { useRef } from "react";
const player = require("./../../Art/Player/TIAC ship.png");
const playerleft = require("./../../Art/Player/TIAC ship left.png");
const playerRight = require("./../../Art/Player/TIAC ship right.png");

const PlayerImgs = () => {
  const playerRef = useRef(null);
  const playerleftRef = useRef(null);
  const playerRightRef = useRef(null);

  globalStore.playerImgs = [playerRef, playerleftRef, playerRightRef];
  return (
    <>
      <img src={player} ref={playerRef} className="hidden" alt="" />

      <img src={playerleft} ref={playerleftRef} className="hidden" alt="" />

      <img src={playerRight} ref={playerRightRef} className="hidden" alt="" />
    </>
  );
};
export default PlayerImgs;
