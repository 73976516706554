import React from "react";
import { observer } from "mobx-react";
import Button from "../../Common/Button/Button";
import logo from "./../../Assets/Logo/TIAC white.png";
import TiS from "./../../Assets/Logo/tiac in space n b.png";
import "./index.css";
import globalStore from "../../Store/GlobalStore";

const HomePage = () => {
  return (
    <div className="home-container">
      <div id="logo">
        <img src={logo}></img>
      </div>
      <div id="tis">
        <img src={TiS}></img>
      </div>
      <div className="home-buttons">
        <Button
          name="Tiac in Space"
          action={() => globalStore.changePage("user-info")}
          classes="btn btn-light"
        />
        <Button
          name="Rang lista"
          action={() => globalStore.changePage("score")}
          classes="btn btn-light"
        />
        <Button
          name="Uputstva"
          action={() => globalStore.changePage("instructions")}
          classes="btn btn-light"
        />
      </div>
    </div>
  );
};
export default observer(HomePage);
