import React from "react";
import "./index.css";

const ScoreTableHeader = () => {
  return (
    <tr>
      <th>#</th>
      <th className="table-cell-email">E-mail</th>
      <th>Rezultat</th>
    </tr>
  );
};
export default ScoreTableHeader;
