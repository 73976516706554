import TransferImage from "./../Helper/TransferImage/TransferImage";

class Dropable {
  constructor(game) {
    this.game = game;
    this.x = -150;
    this.y = 150;
    this.speed = 2;
    this.width = 60;
    this.height = 60;
    this.free = true;

    this.state = 0;
  }

  draw(context) {
    if (this.img) {
      context.drawImage(
        this.img.current,
        this.x,
        this.y,
        this.width,
        this.height
      );
    }
  }

  update() {
    if (!this.free) {
      this.y += this.speed;
      if (this.game.checkCoallision(this, this.game.player)) {
        this.powerUp();
      }
      if (this.y > this.game.height) {
        this.reset();
      }
    }
    if (this.game.spriteUpdate) {
      var trans = TransferImage(this.imgs, this.state);
      this.img = trans[0];
      this.state = trans[1];
    }
  }

  reset() {
    this.x = -150;
    this.y = 150;
    this.free = true;
  }
  start(x, y) {
    this.x = x;
    this.y = y;
    this.free = false;
  }

  powerUp() {
    this.reset();
  }
}

export default Dropable;
