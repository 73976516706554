import globalStore from "../../../../../../Store/GlobalStore";

export default class Laser {
  constructor(game) {
    this.game = game;
    this.width = 10;
    this.height = 10;
    this.prepareWidth = 10;
    this.prepareheight = 10;
    this.beamWidth = 10;
    this.x = 0;
    this.y = 0;
    this.free = true;
    this.canMakeDamage = false;
    this.imgs = globalStore.laserImgs;

    this.formChange = 1000;
    this.form = 0;
    this.formColour = ["green", "yellow", "red"];
    this.formSize = [
      { width: 10, height: 10 },
      { width: 15, height: 15 },
      { width: 7, height: 15000 },
    ];
    this.timerId = null;
    this.enemy = null;
  }

  draw(context) {
    if (!this.free) {
      // context.fillRect(this.x, this.y, this.width, this.height);
      if (this.img) {
        context.drawImage(
          this.img.current,
          this.x,
          this.y,
          this.width,
          this.height
        );
      }
    }
  }

  update() {
    if (this.enemy && this.enemy.life > 0 && !this.free) {
      if (this.form > 4 && this.timerId != null) {
        //CLEAR
        clearInterval(this.timerId);
        this.timerId = null;
        this.width = 0;
        this.height = 0;

        this.x = 0;
        this.y = 0;
        this.free = true;
      } else if (this.form === 4) {
        //FIRED
        this.img = this.imgs[2];
        this.width = this.beamWidth;
        this.height = this.game.height - this.game.enemySize - this.x; //this is problematic

        this.x = this.enemy.x + this.game.enemySize / 2 - this.width / 2; //- this.game.enemySize;

        this.y = this.enemy.y + this.game.enemySize; //+ this.game.enemySize / 2 - this.width / 2;

        this.height = 1000;
        if (
          this.checkCoallision(this, this.game.player) &&
          this.canMakeDamage
        ) {
          this.canMakeDamage = false;
          this.game.player.lives--;
          if (!this.game.gameOver && this.game.score > 0) this.game.score--;
          if (!this.game.gameOver && this.game.player.lives < 1) {
            this.game.loseGame();
          }
        }
      } else if (this.form === 3) {
        //PREPARE BIG

        this.img = this.imgs[1];
        this.width = this.prepareWidth * 2;
        this.height = this.prepareheight * 2;

        this.x = this.enemy.x + this.game.enemySize / 2 - this.width * 0.33; //- this.game.enemySize;
        this.y = this.enemy.y + this.game.enemySize; //+ this.game.enemySize / 2 - this.width / 2;
      } else if ((this.form = 2)) {
        //PREPARE SMOLL

        this.img = this.imgs[0];
        this.width = this.prepareWidth;
        this.height = this.prepareheight;

        this.x = this.enemy.x + this.game.enemySize / 2 - this.width * 0.2; //- this.game.enemySize;
        this.y = this.enemy.y + this.game.enemySize; //+ this.game.enemySize / 2 - this.width / 2;
      } else if (this.form < 2) {
        this.width = 0;
        this.height = 0;

        this.x = 0; //- this.game.enemySize;
        this.y = 0; //+ this.game.enemySize / 2 - this.width / 2;
      }
    } else if (this.enemy != null && this.enemy.markForDelition) {
      this.free = true;
    }
  }
  changeLaserForm = (milisec) => {
    this.timerId = setInterval(() => {
      this.form++;
    }, milisec);
  };

  start(enemy) {
    this.enemy = enemy;
    this.free = false;
    this.canMakeDamage = true;
    this.changeLaserForm(this.formChange);
  }

  reset() {
    this.free = true;
  }

  // collision detecttion
  checkCoallision(a, b) {
    return a.x < b.x + b.width && a.x + a.width > b.x;
  }
}
