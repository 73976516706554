import globalStore from "../../../../../Store/GlobalStore";
import { useRef } from "react";
const bear1 = require("./../../../Art/Dropable/Bear/bear 1.png");
const bear2 = require("./../../../Art/Dropable/Bear/bear 2.png");
const bear3 = require("./../../../Art/Dropable/Bear/bear 3.png");
const bear4 = require("./../../../Art/Dropable/Bear/bear 4.png");
const bear5 = require("./../../../Art/Dropable/Bear/bear 5.png");
const bear6 = require("./../../../Art/Dropable/Bear/bear 6.png");

const BearImgs = () => {
  const bear1Ref = useRef(null);
  const bear2Ref = useRef(null);
  const bear3Ref = useRef(null);
  const bear4Ref = useRef(null);
  const bear5Ref = useRef(null);
  const bear6Ref = useRef(null);

  globalStore.setBearDoppablesImgs([
    bear1Ref,
    bear2Ref,
    bear3Ref,
    bear4Ref,
    bear5Ref,
    bear6Ref,
  ]);
  return (
    <>
      <img src={bear1} ref={bear1Ref} className="hidden" alt="" />

      <img src={bear2} ref={bear2Ref} className="hidden" alt="" />

      <img src={bear3} ref={bear3Ref} className="hidden" alt="" />

      <img src={bear4} ref={bear4Ref} className="hidden" alt="" />

      <img src={bear5} ref={bear5Ref} className="hidden" alt="" />

      <img src={bear6} ref={bear6Ref} className="hidden" alt="" />
    </>
  );
};
export default BearImgs;
