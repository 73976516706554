import globalStore from "../../../../../Store/GlobalStore";
import { useRef } from "react";
const eagle1 = require("./../../../Art/Dropable/Eagle/eagle 1.png");
const eagle2 = require("./../../../Art/Dropable/Eagle/eagle 2.png");
const eagle3 = require("./../../../Art/Dropable/Eagle/eagle 3.png");
const eagle4 = require("./../../../Art/Dropable/Eagle/eagle 4.png");
const eagle5 = require("./../../../Art/Dropable/Eagle/eagle 5.png");
const eagle6 = require("./../../../Art/Dropable/Eagle/eagle 6.png");

const EagleImgs = () => {
  const eagle1Ref = useRef(null);
  const eagle2Ref = useRef(null);
  const eagle3Ref = useRef(null);
  const eagle4Ref = useRef(null);
  const eagle5Ref = useRef(null);
  const eagle6Ref = useRef(null);

  globalStore.setEagleDoppablesImgs([
    eagle1Ref,
    eagle2Ref,
    eagle3Ref,
    eagle4Ref,
    eagle5Ref,
    eagle6Ref,
  ]);
  return (
    <>
      <img src={eagle1} ref={eagle1Ref} className="hidden" alt="" />

      <img src={eagle2} ref={eagle2Ref} className="hidden" alt="" />

      <img src={eagle3} ref={eagle3Ref} className="hidden" alt="" />

      <img src={eagle4} ref={eagle4Ref} className="hidden" alt="" />

      <img src={eagle5} ref={eagle5Ref} className="hidden" alt="" />

      <img src={eagle6} ref={eagle6Ref} className="hidden" alt="" />
    </>
  );
};
export default EagleImgs;
