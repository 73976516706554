import globalStore from "../../../../../Store/GlobalStore";
import { useRef } from "react";
const bison1 = require("./../../../Art/Dropable/Bison/bison 1.png");
const bison2 = require("./../../../Art/Dropable/Bison/bison 2.png");
const bison3 = require("./../../../Art/Dropable/Bison/bison 3.png");
const bison4 = require("./../../../Art/Dropable/Bison/bison 4.png");
const bison5 = require("./../../../Art/Dropable/Bison/bison 5.png");
const bison6 = require("./../../../Art/Dropable/Bison/bison 6.png");

const BisonImgs = () => {
  const bison1Ref = useRef(null);
  const bison2Ref = useRef(null);
  const bison3Ref = useRef(null);
  const bison4Ref = useRef(null);
  const bison5Ref = useRef(null);
  const bison6Ref = useRef(null);

  globalStore.setBisonDoppablesImgs([
    bison1Ref,
    bison2Ref,
    bison3Ref,
    bison4Ref,
    bison5Ref,
    bison6Ref,
  ]);
  return (
    <>
      <img src={bison1} ref={bison1Ref} className="hidden" alt="" />

      <img src={bison2} ref={bison2Ref} className="hidden" alt="" />

      <img src={bison3} ref={bison3Ref} className="hidden" alt="" />

      <img src={bison4} ref={bison4Ref} className="hidden" alt="" />

      <img src={bison5} ref={bison5Ref} className="hidden" alt="" />

      <img src={bison6} ref={bison6Ref} className="hidden" alt="" />
    </>
  );
};
export default BisonImgs;
