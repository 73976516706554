export default class Enemy {
  constructor(game, positionX, positionY) {
    this.game = game;
    this.width = this.game.enemySize;
    this.height = this.game.enemySize;
    this.x = 0;
    this.y = 0;
    this.positionX = positionX;
    this.positionY = positionY;
    this.markForDelition = false;
  }
  draw(context) {
    if (this.img) {
      context.drawImage(
        this.img.current,
        this.x,
        this.y,
        this.width,
        this.height
      );
    }
  }
  update(a, b) {
    this.x = a + this.positionX;
    this.y = b + this.positionY;
    //check collision enemies - projectile
    this.game.projectilesPool.forEach((p) => {
      if (!p.free && this.game.checkCoallision(this, p)) {
        this.getShoot(p);
        if (!this.game.gameOver) this.game.score++;
      }
    });

    //check collision enemies - player
    if (this.game.checkCoallision(this, this.game.player)) {
      this.markForDelition = true;
      this.game.player.lives--;
      if (!this.game.gameOver && this.game.score > 0) this.game.score--;
      if (!this.game.gameOver && this.game.player.lives < 1) {
        this.game.loseGame();
      }
    }
    //lose condition
    if (this.y + this.height > this.game.height) {
      // this.game.gameOver = true;
      this.markForDelition = true;
    }
  }
  getShoot(p) {
    this.life -= this.game.player.damage;
    p.reset();
    if (this.life < 1) {
      p.reset();
      this.markForDelition = true;
      this.dropSomething();
    }
  }

  dropSomething() {
    const dropable = this.game.getDropable();
    if (dropable) {
      dropable.start(this.x, this.y);
    }
  }
}
