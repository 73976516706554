import globalStore from "../../../../../Store/GlobalStore";
import { useRef } from "react";
const laser1 = require("./../../../Art/Ammo/Laser/enemy laser 1.png");
const laser2 = require("./../../../Art/Ammo/Laser/enemy laser 2.png");
const laser3 = require("./../../../Art/Ammo/Laser/enemy laser 3.png");

const LaserImgs = () => {
  const laser1Ref = useRef(null);
  const laser2Ref = useRef(null);
  const laser3Ref = useRef(null);

  globalStore.laserImgs = [laser1Ref, laser2Ref, laser3Ref];
  return (
    <>
      <img src={laser1} ref={laser1Ref} className="hidden" alt="" />

      <img src={laser2} ref={laser2Ref} className="hidden" alt="" />

      <img src={laser3} ref={laser3Ref} className="hidden" alt="" />
    </>
  );
};
export default LaserImgs;
