import axios from "axios";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import Button from "../../Common/Button/Button";
import Input from "../../Common/Input/Input";
import _Global from "../../Utils/GlobalProps";

import "./index.css";

import logo from "../../Assets/Logo/TIAC white.png";
import globalStore from "../../Store/GlobalStore";

const UserInfo = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const submit = () => {
    const valid = validate();

    if (!valid) {
      return;
    }

    axios
      .post(`${_Global.BE_URL}/userResult/create/`, {
        name: name,
        email: email,
        tel: tel,
      })
      .then((res) => {
        globalStore.editUser({ ...res.data });
        globalStore.changePage("game");
      });
  };

  useEffect(() => {
    if (name !== "" && email !== "") {
      setCanSubmit(true);
    }
  }, [name, email, tel]);

  const validate = () => {
    let valid = true;

    if (name === "" || name.split(" ").length < 2) {
      setNameError(true);
      valid = false;
    } else {
      setNameError(false);
    }

    if (
      email === "" ||
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
    ) {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }

    return valid;
  };
  return (
    <div className="border-wraper">
      <div className="small-logo">
        <img src={logo}></img>
      </div>

      <p className="text-white mt-5">
        Upiši svoje podatke kako bi započeo igru.
      </p>

      <form className="">
        <Input name="Ime i prezime" value={name} type="text" action={setName} />
        {nameError && (
          <div className="invalid-feedback mb-1">
            Moraš uneti ime i prezime.
          </div>
        )}

        <Input name="E-mail" value={email} type="email" action={setEmail} />
        {emailError && (
          <div className="invalid-feedback mb-1">
            Moraš uneti validan e-mail.
          </div>
        )}

        <Input
          name="Kontakt telefon"
          type="number"
          value={tel}
          action={setTel}
        />
      </form>

      <p className="mb-3 text-white">
        Svi lični podaci biće korišćeni u svrhu najave događaja u kompaniji
        TIAC.
      </p>

      <div className="d-grid gap-2">
        <Button
          isActive={canSubmit}
          name="igra"
          action={() => submit()}
          classes="btn btn-light"
        ></Button>
        <Button
          name="Rang lista"
          action={() => globalStore.changePage("score")}
          classes="btn btn-light"
        />
      </div>
    </div>
  );
};
export default observer(UserInfo);
