import { observer } from "mobx-react";
import "./App.css";
import CanvasComponent from "./Game/Canvas/Canvas";
import HomePage from "./Pages/HomePage/HomePage";
import UserInfo from "./Pages/UserInfo/UserInfo";
import Score from "./Pages/Score/Score";
import globalStore from "./Store/GlobalStore";
import "bootstrap/dist/css/bootstrap.min.css";
import Instruction from "./Pages/Instruction/Instruction";

function App() {
  switch (globalStore.student.page) {
    case "home":
      return <HomePage user={globalStore.student} />;
    case "game":
      return <CanvasComponent />;
    case "instructions":
      return <Instruction />;
    case "user-info":
      return <UserInfo />;
    case "score":
      return <Score />;
    default:
      return <HomePage user={globalStore.student} />;
  }
}

export default observer(App);
