import globalStore from "../../../../../Store/GlobalStore";
import { useRef } from "react";
const enemy4 = require("./../../../Art/Enemy/Enemy 4/enemy 4.png");

const Enemy4 = () => {
  const enemy4Ref = useRef(null);

  globalStore.enemy4 = enemy4Ref;
  return (
    <>
      <img src={enemy4} ref={enemy4Ref} className="hidden" alt="" />
    </>
  );
};
export default Enemy4;
