import globalStore from "../../../../../Store/GlobalStore";
import { useRef } from "react";
const enemy3 = require("./../../../Art/Enemy/Enemy 3/enemy 3.png");

const enemyVirus = require("./../../../Art/Enemy/Enemy 3/enemyVirus3.png");
const Enemy3 = () => {
  const enemy3Ref = useRef(null);
  const enemyVirusRef = useRef(null);

  globalStore.enemy3 = enemy3Ref;
  globalStore.enemyVirus3 = enemyVirusRef;
  return (
    <>
      <img src={enemy3} ref={enemy3Ref} className="hidden" alt="" />
      <img src={enemyVirus} ref={enemyVirusRef} className="hidden" alt="" />
    </>
  );
};
export default Enemy3;
