import Dropable from "../Dropable";
import globalStore from "./../../../../../Store/GlobalStore";

class Bison extends Dropable {
  constructor(game) {
    super(game);
    this.name = "bison";

    this.imgs = globalStore.bisonDoppablesImgs;
    this.img = this.imgs[0];
  }
  powerUp() {
    super.powerUp();
    if (this.game.player.projectileIntervalUpdate > 100) {
      this.game.player.projectileIntervalUpdate -=
        this.game.player.intervalIncrees;
    } else {
      this.game.score += this.game.pickupBonus;
    }
  }
}
export default Bison;
