import Enemy from "../Enemy";
import globalStore from "../../../../../Store/GlobalStore";

export default class LaserBoiEnemy extends Enemy {
  constructor(game, positionX, positionY, canFireLaser = false) {
    super(game, positionX, positionY);
    this.life = 2;
    this.strokeStyle = "blue";
    this.canFireLaser = canFireLaser;
    this.laserShot = false;
    this.img = globalStore.enemyVirus3;
  }

  update(a, b) {
    if (this.life < 2) this.strokeStyle = "red";
    super.update(a, b);
    if (!this.laserShot) {
      this.shootLaser();
    }
  }

  shootLaser() {
    this.laserShot = true;
    const laser = this.game.getLaser();
    if (laser) {
      laser.start(this);
    }
  }
}
