import { makeAutoObservable } from "mobx";

class GlobalStore {
  student;
  count;
  bearDoppablesImgs;
  turtleDoppablesImgs;
  bisonDoppablesImgs;
  eagleDoppablesImgs;
  //
  projectileImgs;
  laserImgs;
  //
  enemy1;
  enemy2;
  enemy3;
  enemy4;

  enemyVirus1;
  enemyVirus2;
  enemyVirus3;
  //
  playerImgs;

  //
  shild;

  constructor() {
    this.student = {
      _id: "",
      name: "",
      email: "",
      tel: "",
      page: "gome",
    };
    this.count = 0;
    makeAutoObservable(this);
    //DROPABLES
    this.setBearDoppablesImgs = (x) => {
      this.bearDoppablesImgs = x;
    };

    this.setTurtleDoppablesImgs = (x) => {
      this.turtleDoppablesImgs = x;
    };
    this.setBisonDoppablesImgs = (x) => {
      this.bisonDoppablesImgs = x;
    };
    this.setEagleDoppablesImgs = (x) => {
      this.eagleDoppablesImgs = x;
    };
    // AMMO
    this.setProjectileImgs = (x) => {
      this.projectileImgs = x;
    };
    // ENEMY

    //GLOBAL
    this.editUser = (user) => {
      this.student._id = user._id ?? this.student._id;
      this.student.name = user.name ?? this.student.name;
      this.student.email = user.email ?? this.student.email;
      this.student.tel = user.tel ?? this.student.tel;
      this.student.isActive = true;
      this.student.isSubmitted = false;
    };
    this.changePage = (page) => {
      this.student.page = page;
    };
  }
}

const globalStore = new GlobalStore();
export default globalStore;

//import { observer } from "mobx-react";
