import globalStore from "../../../../Store/GlobalStore";

export default class Player {
  constructor(game) {
    this.game = game;
    this.width = this.game.width * 0.17; //SIZE
    this.height = this.game.width * 0.2; //SIZE
    this.speed = 1;
    this.speedIncrees = 0.5;
    this.maxSpeed = 3;
    this.lives = 3;
    this.maxLives = 5;
    this.fired = false;
    this.damage = 1;
    this.damageIncrees = 1;
    this.maxDamage = 4;
    this.intervalIncrees = 50;

    this.projectileIntervalDefault = 500;
    this.projectileInterval = 500;
    this.projectileIntervalUpdate = 500;
    this.x = this.game.width * 0.5 - this.width / 2;
    this.y = this.game.height - this.height;
    //start shooting
    this.intervalId = this.startAutoShoot(this.projectileInterval);
    this.imgs = globalStore.playerImgs;

    this.img = this.imgs[0];
  }

  draw(context) {
    if (this.img) {
      context.drawImage(
        this.img.current,
        this.x,
        this.y,
        this.width,
        this.height
      );
    }
  }

  update() {
    if (this.game.keys.indexOf("ArrowLeft") > -1) {
      this.x -= this.speed;
      this.img = this.imgs[1];
    } else if (this.game.keys.indexOf("ArrowRight") > -1) {
      this.x += this.speed;
      this.img = this.imgs[2];
    } else {
      this.img = this.imgs[0];
    }
    if (this.x < 0 - this.width * 0.45) {
      this.x = 0 - this.width * 0.45;
    } else if (this.x > this.game.width - this.width * 0.45)
      this.x = this.game.width - this.width * 0.45;

    if (
      !this.game.gameOver &&
      this.projectileInterval !== this.projectileIntervalUpdate
    ) {
      clearInterval(this.intervalId);
      this.projectileInterval = this.projectileIntervalUpdate;
      this.intervalId = this.startAutoShoot(this.projectileInterval);
    }

    if (this.game.gameOver && this.intervalId != null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  shoot() {
    const projectile = this.game.getProjectile(this.game.projectilesPool);
    if (projectile) {
      projectile.start(this.x + this.width * 0.5, this.y);
    }
  }

  restart() {
    this.projectileInterval = this.projectileIntervalDefault;
    this.projectileIntervalUpdate = this.projectileIntervalDefault;
    this.lives = 3;
    this.speed = 1;
    this.x = this.game.width * 0.5 - this.width / 2;
    this.y = this.game.height - this.height;
    if (!this.game.gameOver && this.intervalId == null) {
      this.intervalId = this.startAutoShoot(this.projectileInterval);
    }
  }
  //AUTO SHOOT
  startAutoShoot(projectileInterval) {
    return setInterval(() => this.shoot(), projectileInterval);
  }
  //AUTO SHOOT
  stopAutoShoot() {
    clearInterval(this.intervalId);
  }
}
