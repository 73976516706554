import globalStore from "../../../../../Store/GlobalStore";
import { useRef } from "react";
const enemy1 = require("./../../../Art/Enemy/Enemy 1/enemy 1.png");
const enemyVirus = require("./../../../Art/Enemy/Enemy 1/enemyVirus1.png");

const Enemy1 = () => {
  const enemy1Ref = useRef(null);
  const enemyVirusRef = useRef(null);

  globalStore.enemy1 = enemy1Ref;
  globalStore.enemyVirus1 = enemyVirusRef;
  return (
    <>
      <img src={enemy1} ref={enemy1Ref} className="hidden" alt="" />
      <img src={enemyVirus} ref={enemyVirusRef} className="hidden" alt="" />
    </>
  );
};
export default Enemy1;
