import "./ArtImport.css";
import BearImgs from "./Dropable/Bear/BearImgs";
import EagleImgs from "./Dropable/Eagle/EagleImgs";
import TurtleImgs from "./Dropable/Turtle/TurtleImgs";
import BisonImgs from "./Dropable/Bison/BisonImgs";
import ProjectileImgs from "./Ammo/Projectile/ProjectileImgs";
import Enemy1 from "./Enemy/Enemy 1/Enemy1";
import Enemy3 from "./Enemy/Enemy 3/Enemy3";
import Enemy2 from "./Enemy/Enemy 2/Enemy2";
import Enemy4 from "./Enemy/Enemy 4/Enemy4";
import PlayerImgs from "./Player/Player";
import Shild from "./Game/Shild/Shild";
import LaserImgs from "./Ammo/Laser/LaserImg";
const ArtImport = () => {
  return (
    <div>
      <div>
        <BearImgs />
        <EagleImgs />
        <TurtleImgs />
        <BisonImgs />

        <ProjectileImgs />
        <LaserImgs />

        <Enemy1 />
        <Enemy2 />
        <Enemy3 />
        <Enemy4 />

        <PlayerImgs />

        <Shild />
      </div>
    </div>
  );
};
export default ArtImport;
