import Player from "./Player/Player";
import Wave from "./Enemy/Wave";
import Projectile from "./Player/Projectile";
import Laser from "./Enemy/LaserBoiEnemy/Laser/Laser";
import Bear from "./Dropable/Bear/Bear";
import Turtle from "./Dropable/Turtle/Turtle";
import Bison from "./Dropable/Bison/Bison";
import Eagle from "./Dropable/Eagle/Eagle";
import globalStore from "../../../Store/GlobalStore";
export default class Game {
  constructor(canvas, contols, toggleGameBackground) {
    //screen
    this.canvas = canvas;
    this.width = this.canvas.width; //SIZE
    this.height = this.canvas.height; //SIZE
    this.toggleGameBackground = toggleGameBackground;

    //player
    this.keys = [];
    this.player = new Player(this);

    //projectile
    this.projectilesPool = [];
    this.numberOfProjectiles = 20;
    this.createProjectiles();

    //laser
    this.laserPool = [];
    this.numberOfLasers = 10;
    this.createLasers();
    this.interval = 0;
    this.fired = false;

    //enemy Wave
    this.columns = 2;
    this.rows = 1;
    this.maxColumns = 6;
    this.maxRows = 6;
    this.enemySize = this.width * 0.11; //SIZE
    this.waves = [];
    this.waves.push(new Wave(this));
    this.waveCount = 1;
    this.waveSpeed = 1;
    this.waveSpeedIncreesment = 0.2;

    //dropable
    this.dropablePool = [];
    this.createDropables();

    //game status
    this.score = 0;
    this.pickupBonus = 10;
    this.gameOver = false;
    this.shildImg = globalStore.shild;

    //framer
    this.spriteUpdate = false;
    this.spriteTimer = 0;
    this.spriteInterval = 30;

    //event listeners
    const onPress = (diretion) => {
      // Add your code here for what should happen on press
      if (this.keys.indexOf(diretion) === -1) this.keys.push(diretion);
    };
    const onRelease = (diretion) => {
      const i = this.keys.indexOf(diretion);
      if (i > -1) this.keys.splice(i, 1);
    };
    //RESTART
    contols.btnRestart.current.addEventListener("mousedown", (e) => {
      if (this.gameOver) this.restart();
    });
    //LEFT

    contols.btnLeft.current.addEventListener("mousedown", (e) =>
      onPress("ArrowLeft")
    );
    contols.btnLeft.current.addEventListener("touchstart", (e) =>
      onPress("ArrowLeft")
    );
    contols.btnLeft.current.addEventListener("mouseup", (e) =>
      onRelease("ArrowLeft")
    );
    contols.btnLeft.current.addEventListener("touchend", (e) =>
      onRelease("ArrowLeft")
    );
    //RIGHT
    contols.btnRight.current.addEventListener("mousedown", () => {
      onPress("ArrowRight");
    });
    contols.btnRight.current.addEventListener("touchstart", () => {
      onPress("ArrowRight");
    });
    contols.btnRight.current.addEventListener("mouseup", () =>
      onRelease("ArrowRight")
    );
    contols.btnRight.current.addEventListener("touchend", () =>
      onRelease("ArrowRight")
    );

    document.addEventListener("keydown", (e) => {
      if (this.keys.indexOf(e.key) === -1) this.keys.push(e.key);
      if (e.key === " ") {
        this.fired = true;
        this.player.shoot();
      }
    });
    document.addEventListener("keyup", (e) => {
      this.fired = false;
      const i = this.keys.indexOf(e.key);
      if (i > -1) this.keys.splice(i, 1);
    });
  }

  render(context, deltaTime) {
    if (this.spriteTimer > this.spriteInterval) {
      this.spriteUpdate = true;
      this.spriteTimer = 0;
    } else {
      this.spriteUpdate = false;
      this.spriteTimer += deltaTime;
    }
    //player
    this.player.draw(context);
    this.player.update();
    this.drawStatusText(context);

    //projectile
    this.projectilesPool.forEach((pr) => {
      pr.update();
      pr.draw(context);
    });
    //laser
    this.laserPool.forEach((ls) => {
      ls.update();
      ls.draw(context);
    });

    this.dropablePool.forEach((dp) => {
      dp.update();
      dp.draw(context);
    });

    //enemy Wave
    this.waves.forEach((w) => {
      w.render(context);
      if (w.enemies.length < 1 && !w.nextWaveTrigger && !this.gameOver) {
        this.newWave();
        this.waveCount++;
        w.nextWaveTrigger = true;
      }
    });
  }

  //enemy Wave
  newWave() {
    if (
      Math.random() < 0.5 &&
      this.columns * this.enemySize < this.width * 0.8 &&
      this.rows < this.maxRows
    ) {
      this.rows++;
    } else if (
      this.rows * this.enemySize < this.height * 0.8 &&
      this.columns < this.maxColumns
    ) {
      this.columns++;
    }
    this.waves.push(new Wave(this));
    this.waveSpeed += this.waveSpeedIncreesment;
  }
  createLaserPool() {}

  //projectile
  createProjectiles() {
    for (let i = 0; i < this.numberOfProjectiles; i++) {
      this.projectilesPool.push(new Projectile(this));
    }
  }
  getProjectile() {
    for (let i = 0; i < this.projectilesPool.length; i++) {
      if (this.projectilesPool[i].free) return this.projectilesPool[i];
    }
  }
  //lasers
  createLasers() {
    for (let i = 0; i < this.numberOfLasers; i++) {
      this.laserPool.push(new Laser(this));
    }
  }
  getLaser() {
    for (let i = 0; i < this.laserPool.length; i++) {
      if (this.laserPool[i].free) return this.laserPool[i];
    }
  }
  //dropable
  createDropables() {
    this.dropablePool.push(new Bear(this));
    this.dropablePool.push(new Turtle(this));
    this.dropablePool.push(new Bison(this));
    this.dropablePool.push(new Eagle(this));
  }
  getDropable() {
    const untakenElements = this.dropablePool.filter((element) => element.free);
    if (untakenElements.length === 0) {
      return null;
    }
    const randomIndex = Math.floor(Math.random() * untakenElements.length);
    const selectedElement = untakenElements[randomIndex];
    return selectedElement;
  }

  // collision detecttion
  checkCoallision(a, b) {
    return (
      a.x < b.x + b.width &&
      a.x + a.width > b.x &&
      a.y < b.y + b.height &&
      a.y + a.width > b.y
    );
  }
  drawStatusText(context) {
    context.save();
    context.shadowOffsetX = 3;
    context.shadowOffsetY = 3;
    context.shadowColor = "black";
    context.save();
    context.save();
    context.font = "24px troika";
    context.fillText(`Score : ${this.score}`, this.width * 0.05, 40);
    for (let i = 0; i < this.player.lives; i++) {
      // context.fillRect(20 + 10 * i, 100, 16, 20);
      if (this.shildImg) {
        context.drawImage(this.shildImg.current, 20 + 16 * i, 50, 16, 20);
      }
    }
    if (this.gameOver === true) {
      context.textAlign = "center";
      context.font = "20px troika";
      context.fillText(
        `Tvoj score je : ${this.score}`,
        this.width * 0.5,
        this.height * 0.7
      );
    }
    context.restore();
  }

  restart() {
    this.toggleGameBackground(true);
    this.columns = 2;
    this.rows = 2;
    this.waves = [];
    this.waves.push(new Wave(this));
    this.waveCount = 1;
    this.waveSpeed = 1;

    //game status
    this.score = 0;
    this.gameOver = false;
    this.player.restart();
  }

  loseGame() {
    this.gameOver = true;
    this.toggleGameBackground(false);
  }
}
