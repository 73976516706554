import React, { Fragment } from "react";
import "./index.css";

const ScoreTableItem = ({ index = 0, email, score, time }) => {
  return (
    <Fragment>
      <tr>
        <td>{++index}</td>
        <td className="table-cell-email">{email}</td>
        <td>{score} pts</td>
      </tr>
    </Fragment>
  );
};

export default ScoreTableItem;
