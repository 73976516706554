import GruntEnemy from "./GruntEnemy/GruntEnemy";
import GruntEnemy2 from "./GruntEnemy2/GruntEnemy2";
import LaserBoiEnemy from "./LaserBoiEnemy/LaserBoiEnemy";

export default class Wave {
  constructor(game) {
    this.game = game;
    this.radnomGeneratedNum = Math.random() * (0.8 - 0.2) + 0.2;
    this.columns = this.game.columns;
    this.rows = this.game.rows;
    this.enemySize = this.game.enemySize;
    this.width = this.columns * this.enemySize;
    this.height = this.rows * this.enemySize;
    this.x =
      this.game.width * this.radnomGeneratedNum -
      this.width * this.radnomGeneratedNum;
    this.y = -this.height;
    this.speedX = Math.random() < 0.5 ? -1 : 1;
    this.speedY = this.game.waveSpeed ?? 0.5;
    this.startSpeed = this.game.waveSpeed ?? 0.5;
    this.enemies = [];
    this.nextWaveTrigger = false;
    this.create();
  }

  render(context) {
    this.speedY = this.startSpeed;
    //movement
    if (this.y < 0) {
      this.y += 5;
    }
    if (this.x < 0 || this.x > this.game.width - this.width) {
      this.speedX *= -1;
      this.speedY = this.enemySize; //getRandomNumber();
    }

    this.x += this.speedX;
    this.y += this.speedY;

    //enemies
    this.enemies.forEach((enemy) => {
      enemy.update(this.x, this.y);
      enemy.draw(context);
    });
    this.enemies = this.enemies.filter((e) => !e.markForDelition);
  }
  create() {
    for (let y = 0; y < this.rows; y++) {
      for (let x = 0; x < this.columns; x++) {
        let canShootLaser = x < 1;
        let enemyX = x * this.enemySize;
        let enemyY = y * this.enemySize;
        const enemy = this.generateRandomEnemy(enemyX, enemyY, canShootLaser);
        this.enemies.push(enemy);
      }
    }
  }
  static _InstantiateWaves = () => {
    const retval = [];

    return retval;
  };
  generateRandomEnemy(enemyX, enemyY, canShootLaser) {
    const randomNumber = Math.floor(Math.random() * 3) + 1;
    switch (randomNumber) {
      case 1:
        return new GruntEnemy(this.game, enemyX, enemyY);
      case 2:
        return new GruntEnemy2(this.game, enemyX, enemyY);
      case 3:
        return new LaserBoiEnemy(this.game, enemyX, enemyY, true);
      default:
        return new GruntEnemy(this.game, enemyX, enemyY);
    }
  }
}
