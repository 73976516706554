import Enemy from "../Enemy";
import globalStore from "../../../../../Store/GlobalStore";

export default class GruntEnemy extends Enemy {
  constructor(game, positionX, positionY) {
    super(game, positionX, positionY);
    this.life = 3;
    this.strokeStyle = "blue";
    this.canFireLaser = false;
    this.laserShot = false;
    this.img = globalStore.enemyVirus1;
  }

  update(a, b) {
    super.update(a, b);
  }
}
