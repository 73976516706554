import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Controls from "./GamePlay/Controls";
import Game from "./GamePlay/Game";
import ArtImport from "./ArtImport/ArtImport";
import globalStore from "./../../Store/GlobalStore";
import _Global from "./../../Utils/GlobalProps";
import "./Canvas.css";
import "@fortawesome/fontawesome-free/css/all.css";
const CanvasComponent = () => {
  const canvasRef = useRef(null);
  var btnl = useRef(null);
  var btnr = useRef(null);
  var btnRestart = useRef(null);
  const [backgroundClass, setBackgroundClass] = useState("game-on");
  const toggleGameBackground = (restart = false) => {
    if (restart) {
      setBackgroundClass("game-on");
    } else {
      setBackgroundClass("game-over");
    }
  };

  const submit = (score) => {
    debugger;
    const address = `${_Global.BE_URL}/userResult/update/`;
    const payload = {
      ...globalStore.student,
      score: score,
    };
    console.log("patch sent");
    axios.patch(address, payload).then((res) => {
      globalStore.editUser({ ...res.data });
    });
  };
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const contols = new Controls(btnl, btnr, btnRestart);
    const width = document.documentElement.scrollWidth;
    const height = document.documentElement.scrollHeight;

    canvas.width = width * 0.98;
    canvas.height = height * 0.81;
    ctx.fillStyle = "white";
    ctx.strokeStyle = "white";
    ctx.lineWidth = 3;
    ctx.font = "20px Impact";

    const game = new Game(canvas, contols, toggleGameBackground);
    //   game.render(ctx);

    let animationFrameId;
    let lastTime = 0;
    const animate = (timeStamp) => {
      const deltaTime = timeStamp - lastTime;
      lastTime = timeStamp;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      try {
        game.render(ctx, deltaTime);
      } catch (e) {
        console.log(e);
        return;
      }
      if (game.gameOver) {
        console.log("game ended here");
        submit(game.score);
        // cancelAnimationFrame(animationFrameId);
        // return;
      }
      animationFrameId = requestAnimationFrame(animate);
    };
    animate(0);
  }, []); // This effect runs only once after the component mounts

  return (
    <div>
      <div className="content">
        <div className="game">
          <canvas
            ref={canvasRef}
            id="canvas"
            className={backgroundClass}
          ></canvas>
        </div>
        <div className="contolls">
          <button className=" btn-custom btn-custom-left button" ref={btnl}>
            <i className="fas fa-play fa-flip-horizontal"></i>
          </button>
          <button className=" btn-custom  button hidden" ref={btnRestart}>
            <i className="fa-solid fa-rotate-right"></i>
          </button>
          <button
            className=" btn-custom  button "
            //ref={btnRestart}
            onClick={() => {
              globalStore.changePage("score");
            }}
            disabled={backgroundClass === "game-on"}
          >
            {backgroundClass !== "game-on" && "rezultati"}
          </button>
          <button className=" btn-custom btn-custom-right button" ref={btnr}>
            <i className="fas fa-play"></i>
          </button>
        </div>
        <div>
          <ArtImport />
        </div>
      </div>
    </div>
  );
};

export default CanvasComponent;
