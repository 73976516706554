import Dropable from "../Dropable";
import globalStore from "./../../../../../Store/GlobalStore";

class Turtle extends Dropable {
  constructor(game) {
    super(game);
    this.name = "turtle";

    this.imgs = globalStore.turtleDoppablesImgs;
    this.img = this.imgs[0];
  }
  powerUp() {
    if (this.game.player.lives < this.game.player.maxLives) {
      this.game.player.lives++;
    } else {
      this.game.score += this.game.pickupBonus;
    }
    super.powerUp();
  }
}
export default Turtle;
