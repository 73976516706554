import globalStore from "./../../../../Store/GlobalStore";
import TransferImage from "./../Helper/TransferImage/TransferImage";

export default class Projectile {
  constructor(game) {
    this.game = game;
    this.width = 10; //SIZE
    this.height = 40; //SIZE
    this.x = 0;
    this.y = 0;
    this.speed = 5;
    this.free = true;

    this.imgs = globalStore.projectileImgs;
    this.img = this.imgs[0];
    this.state = 0;
  }

  draw(context) {
    if (!this.free) {
      // context.fillRect(this.x, this.y, this.width, this.height);

      if (this.img) {
        context.drawImage(
          this.img.current,
          this.x,
          this.y,
          this.width,
          this.height
        );
      }
    }
  }

  update() {
    if (!this.free) {
      this.y -= this.speed;
      if (this.y < -this.height) this.reset();
    }

    if (this.game.spriteUpdate) {
      var trans = TransferImage(this.imgs, this.state);
      this.img = trans[0];
      this.state = trans[1];
    }
  }

  start(x, y) {
    this.x = x - this.width;
    this.y = y;
    this.free = false;
  }

  reset() {
    this.free = true;
  }
}
