import React from "react";
import ScoreTableHeader from "./ScoreTableHeader/ScoreTableHeader";
import ScoreTableItem from "./ScoreTableItem/ScoreTableItem";

const ScoreTable = ({ results, classes }) => {
  const classNames = ["table table-hover ", classes ?? ""].join(" ");

  return (
    <div>
      <table className={classNames}>
        <thead>
          <ScoreTableHeader />
        </thead>
        <tbody>
          {results.map((item, index) => {
            return (
              <ScoreTableItem
                index={index}
                key={`${item.__id}-${index}`}
                __id={item.__id}
                name={item.name}
                email={item.email}
                score={item.score}
                time={item.time}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ScoreTable;
