import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "../../Common/Button/Button";
import "./index.css";
import ScoreTable from "./ScoreTable/ScoreTable";
import _Global from "../../Utils/GlobalProps";
import globalStore from "./../../Store/GlobalStore";

import logo from "../../Assets/Logo/TIAC white.png";

const Score = ({ user }) => {
  const [result, setResult] = useState([]);
  const update = () => {
    axios
      .post(`${_Global.BE_URL}/userResult/getSortedUserResults/`, {
        topResults: _Global.TOP_RESULTS,
      })
      .then((response) => {
        setResult(response.data.userResults);
      });
  };
  useEffect(() => update(), []);

  return (
    <div className="score-container">
      <div className="small-logo">
        <img src={logo}></img>
      </div>
      <div className="score-content">
        <h1 className="title text-white">Rang lista</h1>
        <Button
          name="Refresh"
          action={update}
          classes="float-end mb-2 refresh"
          // isIcon={true}
          // icon="fa-solid fa-rotate-right"
        />

        <ScoreTable results={result} classes="mt-1 custom-table" />
      </div>{" "}
      <Button
        name="PLAY"
        action={() =>
          globalStore.changePage(globalStore.student._id ? "game" : "user-info")
        }
        classes="btn btn-light"
      />
    </div>
  );
};
export default Score;
