import globalStore from "../../../../../Store/GlobalStore";
import { useRef } from "react";
const turtle1 = require("./../../../Art/Dropable/Turtle/turtle 1.png");
const turtle2 = require("./../../../Art/Dropable/Turtle/turtle 2.png");
const turtle3 = require("./../../../Art/Dropable/Turtle/turtle 3.png");
const turtle4 = require("./../../../Art/Dropable/Turtle/turtle 4.png");
const turtle5 = require("./../../../Art/Dropable/Turtle/turtle 5.png");
const turtle6 = require("./../../../Art/Dropable/Turtle/turtle 6.png");

const TurtleImgs = () => {
  const turtle1Ref = useRef(null);
  const turtle2Ref = useRef(null);
  const turtle3Ref = useRef(null);
  const turtle4Ref = useRef(null);
  const turtle5Ref = useRef(null);
  const turtle6Ref = useRef(null);

  globalStore.setTurtleDoppablesImgs([
    turtle1Ref,
    turtle2Ref,
    turtle3Ref,
    turtle4Ref,
    turtle5Ref,
    turtle6Ref,
  ]);
  return (
    <>
      <img src={turtle1} ref={turtle1Ref} className="hidden" alt="" />

      <img src={turtle2} ref={turtle2Ref} className="hidden" alt="" />

      <img src={turtle3} ref={turtle3Ref} className="hidden" alt="" />

      <img src={turtle4} ref={turtle4Ref} className="hidden" alt="" />

      <img src={turtle5} ref={turtle5Ref} className="hidden" alt="" />

      <img src={turtle6} ref={turtle6Ref} className="hidden" alt="" />
    </>
  );
};
export default TurtleImgs;
