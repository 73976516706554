import globalStore from "../../../../../Store/GlobalStore";
import { useRef } from "react";
const enemy2 = require("./../../../Art/Enemy/Enemy 2/enemy 2.png");
const enemyVirus = require("./../../../Art/Enemy/Enemy 2/enemyVirus2.png");

const Enemy2 = () => {
  const enemy2Ref = useRef(null);
  const enemyVirusRef = useRef(null);

  globalStore.enemy2 = enemy2Ref;
  globalStore.enemyVirus2 = enemyVirusRef;
  return (
    <>
      <img src={enemy2} ref={enemy2Ref} className="hidden" alt="" />
      <img src={enemyVirus} ref={enemyVirusRef} className="hidden" alt="" />
    </>
  );
};
export default Enemy2;
