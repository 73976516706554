import React from "react";
import "./index.css";

// import { ArrowClockwise } from 'react-bootstrap-icons';

// export interface IButton {
//   name: string;
//   isHidden?: boolean;
//   isActive?: boolean;
//   action: () => void;
//   classes?: string;
//   isIcon?: boolean;
//   icon?: string;
// }

const Button = ({
  name,
  isHidden = false,
  isActive = true,
  action,
  classes = "",
  isIcon = false,
  icon = "",
}) => {
  if (!isIcon) {
    return (
      <button
        type="button"
        className={classes}
        onClick={() => action()}
        disabled={!isActive}
      >
        {name}
      </button>
    );
  }

  return (
    <span className={classes} onClick={() => action()}>
      x{/* <ArrowClockwise fontSize={37} color="white" /> */}
    </span>
  );
};
export default Button;
