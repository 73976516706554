import globalStore from "../../../../../Store/GlobalStore";
import { useRef } from "react";
const projectile1 = require("./../../../Art/Ammo/Projectile/TIAC bullet 1.png");
const projectile2 = require("./../../../Art/Ammo/Projectile/TIAC bullet 2.png");
const projectile3 = require("./../../../Art/Ammo/Projectile/TIAC bullet 3.png");
const projectile4 = require("./../../../Art/Ammo/Projectile/TIAC bullet 4.png");

const ProjectileImgs = () => {
  const projectile1Ref = useRef(null);
  const projectile2Ref = useRef(null);
  const projectile3Ref = useRef(null);
  const projectile4Ref = useRef(null);

  globalStore.setProjectileImgs([
    projectile1Ref,
    projectile2Ref,
    projectile3Ref,
    projectile4Ref,
  ]);
  return (
    <>
      <img src={projectile1} ref={projectile1Ref} className="hidden" alt="" />

      <img src={projectile2} ref={projectile2Ref} className="hidden" alt="" />

      <img src={projectile3} ref={projectile3Ref} className="hidden" alt="" />

      <img src={projectile4} ref={projectile4Ref} className="hidden" alt="" />
    </>
  );
};
export default ProjectileImgs;
