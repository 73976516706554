import Dropable from "../Dropable";
import globalStore from "./../../../../../Store/GlobalStore";

class Bear extends Dropable {
  constructor(game) {
    super(game);
    this.name = "bear";

    this.imgs = globalStore.bearDoppablesImgs;
    this.img = this.imgs[0];
  }
  powerUp() {
    super.powerUp();
    this.game.player.damage++;
    if (this.game.player.damage < this.game.player.maxDamage) {
      this.game.player.damage += this.game.player.damageIncrees;
    } else {
      this.game.score += this.game.pickupBonus;
    }
  }
}
export default Bear;
