import globalStore from "../../../../../Store/GlobalStore";
import { useRef } from "react";
const shild = require("./../../../Art/Game/Shild/shield green.png");

const Shild = () => {
  const shildRef = useRef(null);

  globalStore.shild = shildRef;
  return <img src={shild} ref={shildRef} className="hidden" alt="" />;
};
export default Shild;
